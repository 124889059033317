





















































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "node_modules/axios";

export default defineComponent({
  components: {
    MCompanyInput: () => import("@/components/molecules/m-company-input.vue"),
  },

  setup(_, { root }) {
    const state = reactive({
      open: false,
      loading: false,
    });

    const search = ref("");

    const companies = ref<any[]>([]);

    const company = computed({
      get: () => root.$store.getters["companyMode/getCompanyData"] || {},
      set: (value) => root.$store.dispatch("companyMode/setCompany", value.id),
    });

    const removeCompany = () =>
      root.$store.dispatch("companyMode/removeCompany");

    const open = () => (state.open = true);
    const close = () => (state.open = false);

    const fetch = () => {
      const $axios = root.$store.getters["api/getInstance"] as AxiosInstance;

      state.loading = true;

      $axios
        .get("company", { params: { search: search.value || undefined } })
        .then(({ data }) => (companies.value = data.companies))
        .catch(console.log)
        .finally(() => (state.loading = false));
    };

    onMounted(fetch);
    watch(search, fetch);

    const slot = {
      on: { click: open },
      attrs: { company },
    };

    return {
      state,
      search,
      companies,
      company,
      removeCompany,
      open,
      close,
      slot,
    };
  },
});
